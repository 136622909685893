import React, { useState, useEffect } from "react";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { ErrorBoundary } from "react-error-boundary";
import classnames from "classnames";
import { isEmpty, reduce, get } from "lodash";
import { Routes, Route, useParams } from "react-router-dom";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { MdClose as EliminatedIcon } from "react-icons/md";
import PackSource from "./tocpbb-2022/pack.png";
import BlueCard from "./tocpbb-2022/bestball-bluecard.svg";
import tenPNG from "./tocpbb-2022/icons/titans.png";
import kcPNG from "./tocpbb-2022/icons/chiefs.png";
import bufPNG from "./tocpbb-2022/icons/bills.png";
import cinPNG from "./tocpbb-2022/icons/bengals.png";
import lvPNG from "./tocpbb-2022/icons/raiders.png";
import nePNG from "./tocpbb-2022/icons/patriots.png";
import pitPNG from "./tocpbb-2022/icons/steelers.png";
import gbPNG from "./tocpbb-2022/icons/packers.png";
import tbPNG from "./tocpbb-2022/icons/buccaneers.png";
import dalPNG from "./tocpbb-2022/icons/cowboys.png";
import larPNG from "./tocpbb-2022/icons/rams.png";
import ariPNG from "./tocpbb-2022/icons/cardinals.png";
import sfPNG from "./tocpbb-2022/icons/49ers.png";
import phiPNG from "./tocpbb-2022/icons/eagles.png";
import notFoundPNG from "./tocpbb-2022/icons/notfound.png";

import styles from "./App.module.scss";

const VERSION = "v1.5.5";

const teamToSource = {
  titans: tenPNG,
  chiefs: kcPNG,
  bills: bufPNG,
  bengals: cinPNG,
  raiders: lvPNG,
  patriots: nePNG,
  steelers: pitPNG,
  packers: gbPNG,
  buccaneers: tbPNG,
  cowboys: dalPNG,
  rams: larPNG,
  cardinals: ariPNG,
  "49ers": sfPNG,
  eagles: phiPNG,
};

const TEAMS = [
  {
    city: "Arizona",
    name: "Cardinals",
    abbr: "ARZ",
    division: "National",
    isEliminated: true,
  },
  {
    city: "Atlanta",
    name: "Falcons",
    abbr: "ATL",
    division: "National",
  },
  {
    city: "Baltimore",
    name: "Ravens",
    abbr: "BAL",
    division: "American",
  },
  {
    city: "Buffalo",
    name: "Bills",
    abbr: "BUF",
    division: "American",
    isEliminated: true,
  },
  {
    city: "Carolina",
    name: "Panthers",
    abbr: "CAR",
    division: "National",
  },
  {
    city: "Chicago",
    name: "Bears",
    abbr: "CHI",
    division: "National",
  },
  {
    city: "Cincinnati",
    name: "Bengals",
    abbr: "CIN",
    division: "American",
  },
  {
    city: "Cleveland",
    name: "Browns",
    abbr: "CLE",
    division: "American",
  },
  {
    city: "Dallas",
    name: "Cowboys",
    abbr: "DAL",
    division: "National",
    isEliminated: true,
  },
  {
    city: "Denver",
    name: "Broncos",
    abbr: "DEN",
    division: "American",
  },
  {
    city: "Detroit",
    name: "Lions",
    abbr: "DET",
    division: "National",
  },
  {
    city: "Green Bay",
    name: "Packers",
    abbr: "GB",
    division: "National",
    isEliminated: true,
  },
  {
    city: "Houston",
    name: "Texans",
    abbr: "HOU",
    division: "American",
  },
  {
    city: "Indianapolis",
    name: "Colts",
    abbr: "IND",
    division: "American",
  },
  {
    city: "Jacksonville",
    name: "Jaguars",
    abbr: "JAX",
    division: "American",
  },
  {
    city: "Joker",
    name: "Joker",
    abbr: "JKR",
    division: "Joker",
  },
  {
    city: "Kansas City",
    name: "Chiefs",
    abbr: "KC",
    division: "American",
    isEliminated: true,
  },
  {
    city: "Las Vegas",
    name: "Raiders",
    abbr: "LV",
    division: "American",
    isEliminated: true,
  },
  {
    city: "Los Angeles",
    name: "Chargers",
    abbr: "LAC",
    division: "American",
  },
  {
    city: "Los Angeles",
    name: "Rams",
    abbr: "LAR",
    division: "National",
  },
  {
    city: "Miami",
    name: "Dolphins",
    abbr: "MIA",
    division: "American",
  },
  {
    city: "Minnesota",
    name: "Vikings",
    abbr: "MIN",
    division: "National",
  },
  {
    city: "New England",
    name: "Patriots",
    abbr: "NE",
    division: "American",
    isEliminated: true,
  },
  {
    city: "New Orleans",
    name: "Saints",
    abbr: "NO",
    division: "National",
  },
  {
    city: "New York",
    name: "Giants",
    abbr: "NYG",
    division: "National",
  },
  {
    city: "New York",
    name: "Jets",
    abbr: "NYJ",
    division: "American",
  },
  {
    city: "Philadelphia",
    name: "Eagles",
    abbr: "PHI",
    division: "National",
    isEliminated: true,
  },
  {
    city: "Pittsburgh",
    name: "Steelers",
    abbr: "PIT",
    division: "American",
    isEliminated: true,
  },
  {
    city: "San Francisco",
    name: "49ers",
    abbr: "SF",
    division: "National",
    isEliminated: true,
  },
  {
    city: "Seattle",
    name: "Seahawks",
    abbr: "SEA",
    division: "National",
  },
  {
    city: "Tampa Bay",
    name: "Buccaneers",
    abbr: "TB",
    division: "National",
    isEliminated: true,
  },
  {
    city: "Tennessee",
    name: "Titans",
    abbr: "TEN",
    division: "American",
    isEliminated: true,
  },
  {
    city: "Washington",
    name: "Football Team",
    abbr: "WAS",
    division: "National",
  },
];

const calculateScoreLine = (position, stats = {}) => {
  const {
    passing,
    rushing,
    receiving,
    fumbles,
    interceptions,
    kickoffReturns,
    puntReturns,
    twoPointAttempts,
    tackles,
    standings,
    fieldGoals,
    punting,
    extraPointAttempt,
  } = stats;

  switch (position) {
    case "DST":
      return [
        get(tackles, "sacks", 0) && `${get(tackles, "sacks", 0)} sacks`,
        get(interceptions, "interceptions", 0) &&
          `${get(interceptions, "interceptions", 0)} ints`,
        get(fumbles, "fumOppRec", 0) &&
          `${get(fumbles, "fumOppRec", 0)} fum rec`,
        get(interceptions, "safeties", 0) &&
          `${get(interceptions, "safeties", 0)} safeties`,
        get(interceptions, "intTD", 0) &&
          `${get(interceptions, "intTD", 0)} int td`,
        get(fumbles, "fumTD", 0) && `${get(fumbles, "fumTD", 0)} fum td`,
        get(kickoffReturns, "krTD", 0) &&
          `${get(kickoffReturns, "krTD", 0)} kr td`,
        get(puntReturns, "prTD", 0) && `${get(puntReturns, "prTD", 0)} pr td`,
        get(fieldGoals, "fgBlk", 0) && `${get(fieldGoals, "fgBlk", 0)} fg blk`,
        get(punting, "puntBlk", 0) && `${get(punting, "puntBlk", 0)} punt blk`,
        get(extraPointAttempt, "xpBlk", 0) &&
          `${get(extraPointAttempt, "xpBlk", 0)} xp blk`,
        get(standings, "pointsAgainst", 0) &&
          `${get(standings, "pointsAgainst", 0)} pts allowed`,
      ]
        .filter(Boolean)
        .join(", ");
    default:
      return [
        get(passing, "passTD", 0) && `${get(passing, "passTD", 0)} pass td`,
        get(passing, "passYards", 0) &&
          `${get(passing, "passYards", 0)} pass yrds`,
        get(rushing, "rushTD", 0) && `${get(rushing, "rushTD", 0)} rush td`,
        get(rushing, "rushYards", 0) &&
          `${get(rushing, "rushYards", 0)} rush yrds`,
        get(receiving, "recTD", 0) && `${get(receiving, "recTD", 0)} rec td`,
        get(receiving, "recYards", 0) &&
          `${get(receiving, "recYards", 0)} rec yrds`,
        get(twoPointAttempts, "twoPtMade", 0) &&
          `${get(twoPointAttempts, "twoPtMade", 0)} 2pt made`,
        get(passing, "passInt", 0) && `${get(passing, "passInt", 0)} int`,
        get(fumbles, "fumLost", 0) && `${get(fumbles, "fumLost", 0)} fum`,
      ]
        .filter(Boolean)
        .join(", ");
  }
};

const LineupLogo = ({ card, imageSource, isEliminated }) => {
  return (
    <div className={styles.logo}>
      <div className={styles.logo__icon}>
        <img
          src={imageSource}
          alt="Lineup card icon"
          style={{
            height: 60,
            width: 60,
          }}
          title={card.city}
        />
        {isEliminated && <EliminatedIcon className={styles.logo__eliminated} />}
      </div>
      <div className={styles.logo__details}>
        {card.abbreviation} {card.position}
      </div>
    </div>
  );
};

const Accordion = ({ id, header, details, expanded, setExpanded }) => {
  const isOpen = id === expanded;
  return (
    <div
      className={classnames(
        styles.accordion,
        expanded === id ? styles.accordionSelected : ""
      )}
    >
      <motion.header
        className={classnames(styles.accordion__header, {
          [styles.expanded]: isOpen,
        })}
        initial={false}
        onClick={() => setExpanded(isOpen ? false : id)}
      >
        {header}
      </motion.header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            className={styles.accordion__body}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.3 }}
          >
            {details}
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};

const ScoreBreakdownModal = ({ isOpen, token, onClose }) => {
  const { weekly: details, totalPoints } = token;
  const [expanded, setExpanded] = useState(1);
  return (
    <Modal
      className={styles.statsModal}
      show={isOpen}
      onHide={onClose}
      fullscreen
    >
      <Modal.Header closeButton closeVariant="white">
        <h5>SCORING</h5>
      </Modal.Header>
      <Modal.Body>
        <h6 className="mt-4 mb-4 ps-2 pe-2">Total Points: {totalPoints}</h6>
        <div className="d-flex flex-column">
          {["1", "2", "3", "4"].map((week) => {
            const weekly = details[week];

            if (!weekly) {
              return null;
            }

            const weekScore = weekly.totalPoints;

            return (
              <Accordion
                key={week}
                id={week}
                header={
                  <div className="mt-2 mb-2">
                    <h6 className={styles.score}>
                      Week {week}: {weekScore.toFixed(2)}
                    </h6>
                    <div className="d-inline-flex mt-2 mb-2">
                      {["QB", "RB", "WR", "TE", "DST", "SFLEX"].map(
                        (position) => {
                          const details = weekly[position]?.details;

                          const team = TEAMS.find(
                            (team) => team.name === details?.teamName
                          );

                          const iconImageSource = team
                            ? teamToSource[
                                team.name
                                  ?.toLowerCase()
                                  .replace(/[\s\uFEFF\xA0]/g, "")
                              ] || notFoundPNG
                            : notFoundPNG;

                          return (
                            <div
                              key={position}
                              className={classnames(
                                "d-flex flex-column align-items-center justify-content-center",
                                styles.lineup__container
                              )}
                            >
                              <small>
                                <strong>
                                  {position}{" "}
                                  {position === "SFLEX" &&
                                    `: ${weekly[position]?.position || "N/A"}`}
                                </strong>
                              </small>
                              <div className={styles.icon}>
                                <img
                                  src={iconImageSource}
                                  alt="Lineup card icon"
                                  height={45}
                                  width={45}
                                  title={team?.name}
                                />
                              </div>
                              <small>{weekly[position]?.score || 0}</small>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                }
                details={
                  <div
                    className={classnames("d-flex flex-column", styles.weekly)}
                  >
                    <ul className="list-unstyled">
                      {["QB", "RB", "WR", "TE", "DST", "SFLEX"].map(
                        (position) => (
                          <li className="mb-2 p-2">
                            <h6 className="m-0 p-0">
                              {position} -{" "}
                              {weekly[position]?.details?.teamCity || "N/A"}
                            </h6>
                            <div className="d-flex flex-column mb-2">
                              {position === "DST" ? (
                                <div className="d-flex flex-column">
                                  <small>
                                    {calculateScoreLine(
                                      "DST",
                                      weekly[position]?.details?.defense
                                    )}
                                  </small>
                                </div>
                              ) : (
                                !isEmpty(weekly[position]?.details?.players) &&
                                weekly[position].details.players.map(
                                  (player) => (
                                    <div className="d-flex flex-column">
                                      <strong>
                                        {player.playerFullName}:{" "}
                                        {player.playerWeekPoints}
                                      </strong>
                                      <small>
                                        {calculateScoreLine(
                                          player.playerPrimaryPosition,
                                          player.playerWeekStats
                                        )}
                                      </small>
                                    </div>
                                  )
                                )
                              )}
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                }
                expanded={expanded}
                setExpanded={setExpanded}
              />
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const TOCPlayoffBestBall = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [ownerAddress, setOwnerAddress] = useState("");
  const [viewBreakdown, setViewBreakdown] = useState(false);

  const getRandomTransformOrigin = () => {
    const value = (16 + 40 * Math.random()) / 100;
    const value2 = (15 + 36 * Math.random()) / 100;
    return {
      originX: value,
      originY: value2,
    };
  };

  const packVariants = {
    start: (i) => ({
      rotate: i % 2 === 0 ? [-1, 1.3, 0] : [1, -1.4, 0],
      transition: {
        duration: 0.3,
      },
    }),
    reset: {
      rotate: 0,
    },
  };

  const { tokenId } = useParams();

  const controls = useAnimation();

  useEffect(() => {
    const interval = setInterval(() => {
      controls.stop();
      controls.start("start");
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    (async function () {
      try {
        // Make API call to serverless function to get information versus loading the provider here due to sandboxing issues
        const token = await fetch(
          `/api/nfts/tokens/tocpbb2022/${tokenId}`
        ).then((res) => res.json());

        setOwnerAddress(token.ownerAddress);
        setToken(token);
      } catch (error) {
        console.error(error);
        setError("no-data");
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <AnimatePresence>
      <div
        className="d-flex justify-content-center align-items-center overflow-hidden"
        style={{ height: "100vh", width: "100vw" }}
      >
        <div
          className="position-relative w-100 h-100"
          style={{ maxWidth: 500, maxHeight: 500 }}
        >
          {error ? (
            <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
              <img
                src={PackSource}
                alt="NFT dynamic graphic"
                height={375}
                width={300}
              />
              <p>
                <small>PLAYOFF BESTBALL 2022</small>
              </p>
            </div>
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
              {isLoading ? (
                <>
                  <h3>...LOADING EXPERIENCE...</h3>
                  <small>{VERSION}</small>
                </>
              ) : (
                <>
                  {(() => {
                    if (token && (token.isOpened || token.isLineupMinted)) {
                      return token.isLineupMinted ? (
                        <div
                          className={styles.lineup__wrapper}
                          style={{
                            backgroundImage: `url(${BlueCard})`,
                          }}
                        >
                          <div className={styles.lineup}>
                            <Container className={styles.lineup__container}>
                              <Row className={styles.scaling}>
                                {token?.lineup.map((card, idx) => {
                                  const team = TEAMS.find(
                                    (team) => team.name === card.teamName
                                  );
                                  return (
                                    <Col key={idx} xs={4}>
                                      <LineupLogo
                                        imageSource={
                                          teamToSource[
                                            card.teamName
                                              ?.toLowerCase()
                                              .replace(/[\s\uFEFF\xA0]/g, "")
                                          ]
                                        }
                                        card={card}
                                        isEliminated={team.isEliminated}
                                      />
                                    </Col>
                                  );
                                })}
                              </Row>
                              <span className={styles.lineup__token}>
                                #{tokenId}
                              </span>
                            </Container>
                          </div>
                        </div>
                      ) : (
                        <motion.div
                          animate={controls}
                          variants={packVariants}
                          style={{
                            ...getRandomTransformOrigin(),
                            zIndex: 2,
                          }}
                        >
                          <img
                            src={PackSource}
                            alt="NFT pack image"
                            height={375}
                            width={300}
                          />
                        </motion.div>
                      );
                    } else {
                      return (
                        <motion.div
                          animate={controls}
                          variants={packVariants}
                          style={{
                            ...getRandomTransformOrigin(),
                            zIndex: 2,
                          }}
                        >
                          <img
                            src={PackSource}
                            alt="NFT pack image"
                            height={375}
                            width={300}
                          />
                        </motion.div>
                      );
                    }
                  })()}
                  {typeof token?.totalPoints === "number" && (
                    <>
                      <span>
                        <strong className="me-2">Score:</strong>
                        {token.totalPoints}
                      </span>
                      <div
                        className="d-flex"
                        role="button"
                        style={{ cursor: "pointer" }}
                        onClick={() => setViewBreakdown(true)}
                      >
                        <u>
                          <small>View Weekly Scoring</small>
                        </u>
                      </div>
                      {viewBreakdown && (
                        <ScoreBreakdownModal
                          isOpen={true}
                          token={token}
                          onClose={() => setViewBreakdown(false)}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </AnimatePresence>
  );
};

function App() {
  return (
    <ErrorBoundary
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div
          className="d-flex justify-content-center align-items-center overflow-hidden text-center"
          style={{ height: "100vh", width: "100vw" }}
        >
          <div
            className="position-relative w-100 h-100"
            style={{ maxWidth: 500, maxHeight: 500 }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
              <h1>Oops!</h1>
              <h2>There was a loading error.</h2>
              <button
                className="btn btn-secondary mt-4"
                onClick={() => {
                  resetErrorBoundary();
                }}
                style={{ cursor: "pointer" }}
              >
                Click to try again!
              </button>
            </div>
          </div>
        </div>
      )}
    >
      <Routes>
        <Route path="tocpbb-2022/:tokenId" element={<TOCPlayoffBestBall />} />
        <Route path="*" element={<div />} />
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
